import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FindUserCouponsQueryVariables = Types.Exact<{
  input: Types.FindUserCouponsInput;
}>;


export type FindUserCouponsQuery = { __typename?: 'Query', findUserCoupons: { __typename?: 'FindUserCouponsPayload', coupons: Array<{ __typename?: 'Coupon', id: number, name: string, internalCode: string, discountPercentage: number, maxDiscountAmount?: number | null, maxDiscountCurrency?: string | null, maxDiscountScale?: number | null, startDate?: string | null, endDate?: string | null, enabled: boolean, totalGroups?: number | null }> } };

export type FindCouponsQueryVariables = Types.Exact<{
  input: Types.FindCouponsInput;
}>;


export type FindCouponsQuery = { __typename?: 'Query', findCoupons: { __typename?: 'FindCouponsPayload', coupons: Array<{ __typename?: 'Coupon', id: number, name: string, internalCode: string, discountPercentage: number, maxDiscountAmount?: number | null, startDate?: string | null, endDate?: string | null, enabled: boolean, totalGroups?: number | null }>, pagination: { __typename?: 'PageInfo', pageBased?: { __typename?: 'PageBasedInfo', count: number } | null } } };

export type FindCouponByIdQueryVariables = Types.Exact<{
  input: Types.FindCouponByIdInput;
}>;


export type FindCouponByIdQuery = { __typename?: 'Query', findCouponById: { __typename?: 'Coupon', id: number, name: string, internalCode: string, discountPercentage: number, maxDiscountAmount?: number | null, startDate?: string | null, endDate?: string | null, enabled: boolean } };

export type FindCouponGroupsQueryVariables = Types.Exact<{
  input: Types.FindCouponGroupsInput;
}>;


export type FindCouponGroupsQuery = { __typename?: 'Query', findCouponGroups: { __typename?: 'FindCouponGroupsPayload', couponGroups: Array<{ __typename?: 'CouponGroup', id: number, name: string, internalCode: string, enabled: boolean, stores?: number | null }>, pagination: { __typename?: 'PageInfo', pageBased?: { __typename?: 'PageBasedInfo', count: number } | null } } };

export type FindCouponGroupsByCouponIdQueryVariables = Types.Exact<{
  input: Types.FindCouponGroupsByCouponIdInput;
}>;


export type FindCouponGroupsByCouponIdQuery = { __typename?: 'Query', findCouponGroupsByCouponId: { __typename?: 'FindCouponGroupsByCouponIdPayload', couponGroups: Array<{ __typename?: 'CouponGroup', id: number, name: string, internalCode: string, enabled: boolean, stores?: number | null }>, pagination: { __typename?: 'PageInfo', pageBased?: { __typename?: 'PageBasedInfo', count: number } | null } } };

export type FindCouponGroupByIdQueryVariables = Types.Exact<{
  input: Types.FindCouponGroupByIdInput;
}>;


export type FindCouponGroupByIdQuery = { __typename?: 'Query', findCouponGroupById: { __typename?: 'FindCouponGroupByIdPayload', couponGroup: { __typename?: 'CouponGroup', id: number, name: string, internalCode: string, enabled: boolean, stores?: number | null }, stores: Array<{ __typename?: 'StoreInCouponGroup', id: number, name: string, internalCode: string }> } };

export type FindStoresByCouponIdQueryVariables = Types.Exact<{
  input: Types.FindStoresByCouponIdInput;
}>;


export type FindStoresByCouponIdQuery = { __typename?: 'Query', findStoresByCouponId: { __typename?: 'FindStoresByCouponIdPayload', stores: Array<{ __typename?: 'StoreInCouponGroup', id: number, name: string, internalCode: string }>, pagination: { __typename?: 'PageInfo', pageBased?: { __typename?: 'PageBasedInfo', count: number } | null } } };

export type CreateCouponMutationVariables = Types.Exact<{
  input: Types.CreateCouponInput;
}>;


export type CreateCouponMutation = { __typename?: 'Mutation', createCoupon: { __typename?: 'CreateCouponPayload', coupon: { __typename?: 'Coupon', id: number, name: string, internalCode: string, discountPercentage: number, maxDiscountAmount?: number | null, startDate?: string | null, endDate?: string | null, enabled: boolean, totalGroups?: number | null } } };

export type UpdateCouponMutationVariables = Types.Exact<{
  input: Types.UpdateCouponInput;
}>;


export type UpdateCouponMutation = { __typename?: 'Mutation', updateCoupon: { __typename?: 'UpdateCouponPayload', coupon: { __typename?: 'Coupon', id: number, name: string, internalCode: string, discountPercentage: number, maxDiscountAmount?: number | null, startDate?: string | null, endDate?: string | null, enabled: boolean, totalGroups?: number | null } } };

export type CreateCouponGroupMutationVariables = Types.Exact<{
  input: Types.CreateCouponGroupInput;
}>;


export type CreateCouponGroupMutation = { __typename?: 'Mutation', createCouponGroup: { __typename?: 'CouponGroup', id: number, name: string, internalCode: string, enabled: boolean, stores?: number | null } };

export type UpdateCouponGroupMutationVariables = Types.Exact<{
  input: Types.UpdateCouponGroupInput;
}>;


export type UpdateCouponGroupMutation = { __typename?: 'Mutation', updateCouponGroup: { __typename?: 'CouponGroup', id: number, name: string, internalCode: string, enabled: boolean, stores?: number | null } };

export type DeleteCouponGroupMutationVariables = Types.Exact<{
  input: Types.DeleteCouponGroupInput;
}>;


export type DeleteCouponGroupMutation = { __typename?: 'Mutation', deleteCouponGroup: { __typename?: 'CouponGroup', id: number, name: string, internalCode: string, enabled: boolean, stores?: number | null } };

export type SetCouponGroupStatusMutationVariables = Types.Exact<{
  input: Types.SetCouponGroupStatusInput;
}>;


export type SetCouponGroupStatusMutation = { __typename?: 'Mutation', setCouponGroupStatus: { __typename?: 'CouponGroup', id: number, name: string, internalCode: string, enabled: boolean, stores?: number | null } };

export type SetCouponStatusMutationVariables = Types.Exact<{
  input: Types.SetCouponStatusInput;
}>;


export type SetCouponStatusMutation = { __typename?: 'Mutation', setCouponStatus: { __typename?: 'Coupon', id: number, name: string, internalCode: string, discountPercentage: number, maxDiscountAmount?: number | null, startDate?: string | null, endDate?: string | null, enabled: boolean } };

export type AddStoreToCouponGroupMutationVariables = Types.Exact<{
  input: Types.AddStoreToCouponGroupInput;
}>;


export type AddStoreToCouponGroupMutation = { __typename?: 'Mutation', addStoreToCouponGroup: { __typename?: 'AddStoreToCouponGroupPayload', id: number, stores: Array<{ __typename?: 'StoreInCouponGroup', id: number, name: string, internalCode: string }> } };

export type RemoveStoreFromCouponGroupMutationVariables = Types.Exact<{
  input: Types.RemoveStoreFromCouponGroupInput;
}>;


export type RemoveStoreFromCouponGroupMutation = { __typename?: 'Mutation', removeStoreFromCouponGroup: { __typename?: 'RemoveStoreFromCouponGroupPayload', id: number, stores: Array<{ __typename?: 'StoreInCouponGroup', id: number, name: string, internalCode: string }> } };

export type AddCouponGroupToCouponMutationVariables = Types.Exact<{
  input: Types.AddCouponGroupToCouponInput;
}>;


export type AddCouponGroupToCouponMutation = { __typename?: 'Mutation', addCouponGroupToCoupon: { __typename?: 'AddCouponGroupToCouponPayload', coupon: { __typename?: 'Coupon', id: number, name: string, internalCode: string, discountPercentage: number, maxDiscountAmount?: number | null, startDate?: string | null, endDate?: string | null, enabled: boolean, totalGroups?: number | null } } };

export type RemoveCouponGroupFromCouponMutationVariables = Types.Exact<{
  input: Types.RemoveCouponGroupFromCouponInput;
}>;


export type RemoveCouponGroupFromCouponMutation = { __typename?: 'Mutation', removeCouponGroupFromCoupon: { __typename?: 'RemoveCouponGroupFromCouponPayload', coupon: { __typename?: 'Coupon', id: number, name: string, internalCode: string, discountPercentage: number, maxDiscountAmount?: number | null, startDate?: string | null, endDate?: string | null, enabled: boolean, totalGroups?: number | null } } };


export const FindUserCouponsDocument = gql`
    query FindUserCoupons($input: FindUserCouponsInput!) {
  findUserCoupons(input: $input) {
    coupons {
      id
      name
      internalCode
      discountPercentage
      maxDiscountAmount
      maxDiscountCurrency
      maxDiscountScale
      startDate
      endDate
      enabled
      totalGroups
    }
  }
}
    `;

/**
 * __useFindUserCouponsQuery__
 *
 * To run a query within a React component, call `useFindUserCouponsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindUserCouponsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindUserCouponsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindUserCouponsQuery(baseOptions: Apollo.QueryHookOptions<FindUserCouponsQuery, FindUserCouponsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindUserCouponsQuery, FindUserCouponsQueryVariables>(FindUserCouponsDocument, options);
      }
export function useFindUserCouponsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindUserCouponsQuery, FindUserCouponsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindUserCouponsQuery, FindUserCouponsQueryVariables>(FindUserCouponsDocument, options);
        }
export type FindUserCouponsQueryHookResult = ReturnType<typeof useFindUserCouponsQuery>;
export type FindUserCouponsLazyQueryHookResult = ReturnType<typeof useFindUserCouponsLazyQuery>;
export type FindUserCouponsQueryResult = Apollo.QueryResult<FindUserCouponsQuery, FindUserCouponsQueryVariables>;
export const FindCouponsDocument = gql`
    query FindCoupons($input: FindCouponsInput!) {
  findCoupons(input: $input) {
    coupons {
      id
      name
      internalCode
      discountPercentage
      maxDiscountAmount
      startDate
      endDate
      enabled
      totalGroups
    }
    pagination {
      pageBased {
        count
      }
    }
  }
}
    `;

/**
 * __useFindCouponsQuery__
 *
 * To run a query within a React component, call `useFindCouponsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindCouponsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindCouponsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindCouponsQuery(baseOptions: Apollo.QueryHookOptions<FindCouponsQuery, FindCouponsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindCouponsQuery, FindCouponsQueryVariables>(FindCouponsDocument, options);
      }
export function useFindCouponsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindCouponsQuery, FindCouponsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindCouponsQuery, FindCouponsQueryVariables>(FindCouponsDocument, options);
        }
export type FindCouponsQueryHookResult = ReturnType<typeof useFindCouponsQuery>;
export type FindCouponsLazyQueryHookResult = ReturnType<typeof useFindCouponsLazyQuery>;
export type FindCouponsQueryResult = Apollo.QueryResult<FindCouponsQuery, FindCouponsQueryVariables>;
export const FindCouponByIdDocument = gql`
    query FindCouponById($input: FindCouponByIdInput!) {
  findCouponById(input: $input) {
    id
    name
    internalCode
    discountPercentage
    maxDiscountAmount
    startDate
    endDate
    enabled
  }
}
    `;

/**
 * __useFindCouponByIdQuery__
 *
 * To run a query within a React component, call `useFindCouponByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindCouponByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindCouponByIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindCouponByIdQuery(baseOptions: Apollo.QueryHookOptions<FindCouponByIdQuery, FindCouponByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindCouponByIdQuery, FindCouponByIdQueryVariables>(FindCouponByIdDocument, options);
      }
export function useFindCouponByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindCouponByIdQuery, FindCouponByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindCouponByIdQuery, FindCouponByIdQueryVariables>(FindCouponByIdDocument, options);
        }
export type FindCouponByIdQueryHookResult = ReturnType<typeof useFindCouponByIdQuery>;
export type FindCouponByIdLazyQueryHookResult = ReturnType<typeof useFindCouponByIdLazyQuery>;
export type FindCouponByIdQueryResult = Apollo.QueryResult<FindCouponByIdQuery, FindCouponByIdQueryVariables>;
export const FindCouponGroupsDocument = gql`
    query FindCouponGroups($input: FindCouponGroupsInput!) {
  findCouponGroups(input: $input) {
    couponGroups {
      id
      name
      internalCode
      enabled
      stores
    }
    pagination {
      pageBased {
        count
      }
    }
  }
}
    `;

/**
 * __useFindCouponGroupsQuery__
 *
 * To run a query within a React component, call `useFindCouponGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindCouponGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindCouponGroupsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindCouponGroupsQuery(baseOptions: Apollo.QueryHookOptions<FindCouponGroupsQuery, FindCouponGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindCouponGroupsQuery, FindCouponGroupsQueryVariables>(FindCouponGroupsDocument, options);
      }
export function useFindCouponGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindCouponGroupsQuery, FindCouponGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindCouponGroupsQuery, FindCouponGroupsQueryVariables>(FindCouponGroupsDocument, options);
        }
export type FindCouponGroupsQueryHookResult = ReturnType<typeof useFindCouponGroupsQuery>;
export type FindCouponGroupsLazyQueryHookResult = ReturnType<typeof useFindCouponGroupsLazyQuery>;
export type FindCouponGroupsQueryResult = Apollo.QueryResult<FindCouponGroupsQuery, FindCouponGroupsQueryVariables>;
export const FindCouponGroupsByCouponIdDocument = gql`
    query FindCouponGroupsByCouponId($input: FindCouponGroupsByCouponIdInput!) {
  findCouponGroupsByCouponId(input: $input) {
    couponGroups {
      id
      name
      internalCode
      enabled
      stores
    }
    pagination {
      pageBased {
        count
      }
    }
  }
}
    `;

/**
 * __useFindCouponGroupsByCouponIdQuery__
 *
 * To run a query within a React component, call `useFindCouponGroupsByCouponIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindCouponGroupsByCouponIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindCouponGroupsByCouponIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindCouponGroupsByCouponIdQuery(baseOptions: Apollo.QueryHookOptions<FindCouponGroupsByCouponIdQuery, FindCouponGroupsByCouponIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindCouponGroupsByCouponIdQuery, FindCouponGroupsByCouponIdQueryVariables>(FindCouponGroupsByCouponIdDocument, options);
      }
export function useFindCouponGroupsByCouponIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindCouponGroupsByCouponIdQuery, FindCouponGroupsByCouponIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindCouponGroupsByCouponIdQuery, FindCouponGroupsByCouponIdQueryVariables>(FindCouponGroupsByCouponIdDocument, options);
        }
export type FindCouponGroupsByCouponIdQueryHookResult = ReturnType<typeof useFindCouponGroupsByCouponIdQuery>;
export type FindCouponGroupsByCouponIdLazyQueryHookResult = ReturnType<typeof useFindCouponGroupsByCouponIdLazyQuery>;
export type FindCouponGroupsByCouponIdQueryResult = Apollo.QueryResult<FindCouponGroupsByCouponIdQuery, FindCouponGroupsByCouponIdQueryVariables>;
export const FindCouponGroupByIdDocument = gql`
    query FindCouponGroupById($input: FindCouponGroupByIdInput!) {
  findCouponGroupById(input: $input) {
    couponGroup {
      id
      name
      internalCode
      enabled
      stores
    }
    stores {
      id
      name
      internalCode
    }
  }
}
    `;

/**
 * __useFindCouponGroupByIdQuery__
 *
 * To run a query within a React component, call `useFindCouponGroupByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindCouponGroupByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindCouponGroupByIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindCouponGroupByIdQuery(baseOptions: Apollo.QueryHookOptions<FindCouponGroupByIdQuery, FindCouponGroupByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindCouponGroupByIdQuery, FindCouponGroupByIdQueryVariables>(FindCouponGroupByIdDocument, options);
      }
export function useFindCouponGroupByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindCouponGroupByIdQuery, FindCouponGroupByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindCouponGroupByIdQuery, FindCouponGroupByIdQueryVariables>(FindCouponGroupByIdDocument, options);
        }
export type FindCouponGroupByIdQueryHookResult = ReturnType<typeof useFindCouponGroupByIdQuery>;
export type FindCouponGroupByIdLazyQueryHookResult = ReturnType<typeof useFindCouponGroupByIdLazyQuery>;
export type FindCouponGroupByIdQueryResult = Apollo.QueryResult<FindCouponGroupByIdQuery, FindCouponGroupByIdQueryVariables>;
export const FindStoresByCouponIdDocument = gql`
    query FindStoresByCouponId($input: FindStoresByCouponIdInput!) {
  findStoresByCouponId(input: $input) {
    stores {
      id
      name
      internalCode
    }
    pagination {
      pageBased {
        count
      }
    }
  }
}
    `;

/**
 * __useFindStoresByCouponIdQuery__
 *
 * To run a query within a React component, call `useFindStoresByCouponIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindStoresByCouponIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindStoresByCouponIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindStoresByCouponIdQuery(baseOptions: Apollo.QueryHookOptions<FindStoresByCouponIdQuery, FindStoresByCouponIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindStoresByCouponIdQuery, FindStoresByCouponIdQueryVariables>(FindStoresByCouponIdDocument, options);
      }
export function useFindStoresByCouponIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindStoresByCouponIdQuery, FindStoresByCouponIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindStoresByCouponIdQuery, FindStoresByCouponIdQueryVariables>(FindStoresByCouponIdDocument, options);
        }
export type FindStoresByCouponIdQueryHookResult = ReturnType<typeof useFindStoresByCouponIdQuery>;
export type FindStoresByCouponIdLazyQueryHookResult = ReturnType<typeof useFindStoresByCouponIdLazyQuery>;
export type FindStoresByCouponIdQueryResult = Apollo.QueryResult<FindStoresByCouponIdQuery, FindStoresByCouponIdQueryVariables>;
export const CreateCouponDocument = gql`
    mutation CreateCoupon($input: CreateCouponInput!) {
  createCoupon(input: $input) {
    coupon {
      id
      name
      internalCode
      discountPercentage
      maxDiscountAmount
      startDate
      endDate
      enabled
      totalGroups
    }
  }
}
    `;
export type CreateCouponMutationFn = Apollo.MutationFunction<CreateCouponMutation, CreateCouponMutationVariables>;

/**
 * __useCreateCouponMutation__
 *
 * To run a mutation, you first call `useCreateCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCouponMutation, { data, loading, error }] = useCreateCouponMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCouponMutation(baseOptions?: Apollo.MutationHookOptions<CreateCouponMutation, CreateCouponMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCouponMutation, CreateCouponMutationVariables>(CreateCouponDocument, options);
      }
export type CreateCouponMutationHookResult = ReturnType<typeof useCreateCouponMutation>;
export type CreateCouponMutationResult = Apollo.MutationResult<CreateCouponMutation>;
export type CreateCouponMutationOptions = Apollo.BaseMutationOptions<CreateCouponMutation, CreateCouponMutationVariables>;
export const UpdateCouponDocument = gql`
    mutation UpdateCoupon($input: UpdateCouponInput!) {
  updateCoupon(input: $input) {
    coupon {
      id
      name
      internalCode
      discountPercentage
      maxDiscountAmount
      startDate
      endDate
      enabled
      totalGroups
    }
  }
}
    `;
export type UpdateCouponMutationFn = Apollo.MutationFunction<UpdateCouponMutation, UpdateCouponMutationVariables>;

/**
 * __useUpdateCouponMutation__
 *
 * To run a mutation, you first call `useUpdateCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCouponMutation, { data, loading, error }] = useUpdateCouponMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCouponMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCouponMutation, UpdateCouponMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCouponMutation, UpdateCouponMutationVariables>(UpdateCouponDocument, options);
      }
export type UpdateCouponMutationHookResult = ReturnType<typeof useUpdateCouponMutation>;
export type UpdateCouponMutationResult = Apollo.MutationResult<UpdateCouponMutation>;
export type UpdateCouponMutationOptions = Apollo.BaseMutationOptions<UpdateCouponMutation, UpdateCouponMutationVariables>;
export const CreateCouponGroupDocument = gql`
    mutation CreateCouponGroup($input: CreateCouponGroupInput!) {
  createCouponGroup(input: $input) {
    id
    name
    internalCode
    enabled
    stores
  }
}
    `;
export type CreateCouponGroupMutationFn = Apollo.MutationFunction<CreateCouponGroupMutation, CreateCouponGroupMutationVariables>;

/**
 * __useCreateCouponGroupMutation__
 *
 * To run a mutation, you first call `useCreateCouponGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCouponGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCouponGroupMutation, { data, loading, error }] = useCreateCouponGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCouponGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreateCouponGroupMutation, CreateCouponGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCouponGroupMutation, CreateCouponGroupMutationVariables>(CreateCouponGroupDocument, options);
      }
export type CreateCouponGroupMutationHookResult = ReturnType<typeof useCreateCouponGroupMutation>;
export type CreateCouponGroupMutationResult = Apollo.MutationResult<CreateCouponGroupMutation>;
export type CreateCouponGroupMutationOptions = Apollo.BaseMutationOptions<CreateCouponGroupMutation, CreateCouponGroupMutationVariables>;
export const UpdateCouponGroupDocument = gql`
    mutation UpdateCouponGroup($input: UpdateCouponGroupInput!) {
  updateCouponGroup(input: $input) {
    id
    name
    internalCode
    enabled
    stores
  }
}
    `;
export type UpdateCouponGroupMutationFn = Apollo.MutationFunction<UpdateCouponGroupMutation, UpdateCouponGroupMutationVariables>;

/**
 * __useUpdateCouponGroupMutation__
 *
 * To run a mutation, you first call `useUpdateCouponGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCouponGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCouponGroupMutation, { data, loading, error }] = useUpdateCouponGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCouponGroupMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCouponGroupMutation, UpdateCouponGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCouponGroupMutation, UpdateCouponGroupMutationVariables>(UpdateCouponGroupDocument, options);
      }
export type UpdateCouponGroupMutationHookResult = ReturnType<typeof useUpdateCouponGroupMutation>;
export type UpdateCouponGroupMutationResult = Apollo.MutationResult<UpdateCouponGroupMutation>;
export type UpdateCouponGroupMutationOptions = Apollo.BaseMutationOptions<UpdateCouponGroupMutation, UpdateCouponGroupMutationVariables>;
export const DeleteCouponGroupDocument = gql`
    mutation DeleteCouponGroup($input: DeleteCouponGroupInput!) {
  deleteCouponGroup(input: $input) {
    id
    name
    internalCode
    enabled
    stores
  }
}
    `;
export type DeleteCouponGroupMutationFn = Apollo.MutationFunction<DeleteCouponGroupMutation, DeleteCouponGroupMutationVariables>;

/**
 * __useDeleteCouponGroupMutation__
 *
 * To run a mutation, you first call `useDeleteCouponGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCouponGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCouponGroupMutation, { data, loading, error }] = useDeleteCouponGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCouponGroupMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCouponGroupMutation, DeleteCouponGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCouponGroupMutation, DeleteCouponGroupMutationVariables>(DeleteCouponGroupDocument, options);
      }
export type DeleteCouponGroupMutationHookResult = ReturnType<typeof useDeleteCouponGroupMutation>;
export type DeleteCouponGroupMutationResult = Apollo.MutationResult<DeleteCouponGroupMutation>;
export type DeleteCouponGroupMutationOptions = Apollo.BaseMutationOptions<DeleteCouponGroupMutation, DeleteCouponGroupMutationVariables>;
export const SetCouponGroupStatusDocument = gql`
    mutation SetCouponGroupStatus($input: SetCouponGroupStatusInput!) {
  setCouponGroupStatus(input: $input) {
    id
    name
    internalCode
    enabled
    stores
  }
}
    `;
export type SetCouponGroupStatusMutationFn = Apollo.MutationFunction<SetCouponGroupStatusMutation, SetCouponGroupStatusMutationVariables>;

/**
 * __useSetCouponGroupStatusMutation__
 *
 * To run a mutation, you first call `useSetCouponGroupStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCouponGroupStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCouponGroupStatusMutation, { data, loading, error }] = useSetCouponGroupStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetCouponGroupStatusMutation(baseOptions?: Apollo.MutationHookOptions<SetCouponGroupStatusMutation, SetCouponGroupStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetCouponGroupStatusMutation, SetCouponGroupStatusMutationVariables>(SetCouponGroupStatusDocument, options);
      }
export type SetCouponGroupStatusMutationHookResult = ReturnType<typeof useSetCouponGroupStatusMutation>;
export type SetCouponGroupStatusMutationResult = Apollo.MutationResult<SetCouponGroupStatusMutation>;
export type SetCouponGroupStatusMutationOptions = Apollo.BaseMutationOptions<SetCouponGroupStatusMutation, SetCouponGroupStatusMutationVariables>;
export const SetCouponStatusDocument = gql`
    mutation SetCouponStatus($input: SetCouponStatusInput!) {
  setCouponStatus(input: $input) {
    id
    name
    internalCode
    discountPercentage
    maxDiscountAmount
    startDate
    endDate
    enabled
  }
}
    `;
export type SetCouponStatusMutationFn = Apollo.MutationFunction<SetCouponStatusMutation, SetCouponStatusMutationVariables>;

/**
 * __useSetCouponStatusMutation__
 *
 * To run a mutation, you first call `useSetCouponStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCouponStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCouponStatusMutation, { data, loading, error }] = useSetCouponStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetCouponStatusMutation(baseOptions?: Apollo.MutationHookOptions<SetCouponStatusMutation, SetCouponStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetCouponStatusMutation, SetCouponStatusMutationVariables>(SetCouponStatusDocument, options);
      }
export type SetCouponStatusMutationHookResult = ReturnType<typeof useSetCouponStatusMutation>;
export type SetCouponStatusMutationResult = Apollo.MutationResult<SetCouponStatusMutation>;
export type SetCouponStatusMutationOptions = Apollo.BaseMutationOptions<SetCouponStatusMutation, SetCouponStatusMutationVariables>;
export const AddStoreToCouponGroupDocument = gql`
    mutation AddStoreToCouponGroup($input: AddStoreToCouponGroupInput!) {
  addStoreToCouponGroup(input: $input) {
    id
    stores {
      id
      name
      internalCode
    }
  }
}
    `;
export type AddStoreToCouponGroupMutationFn = Apollo.MutationFunction<AddStoreToCouponGroupMutation, AddStoreToCouponGroupMutationVariables>;

/**
 * __useAddStoreToCouponGroupMutation__
 *
 * To run a mutation, you first call `useAddStoreToCouponGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddStoreToCouponGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addStoreToCouponGroupMutation, { data, loading, error }] = useAddStoreToCouponGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddStoreToCouponGroupMutation(baseOptions?: Apollo.MutationHookOptions<AddStoreToCouponGroupMutation, AddStoreToCouponGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddStoreToCouponGroupMutation, AddStoreToCouponGroupMutationVariables>(AddStoreToCouponGroupDocument, options);
      }
export type AddStoreToCouponGroupMutationHookResult = ReturnType<typeof useAddStoreToCouponGroupMutation>;
export type AddStoreToCouponGroupMutationResult = Apollo.MutationResult<AddStoreToCouponGroupMutation>;
export type AddStoreToCouponGroupMutationOptions = Apollo.BaseMutationOptions<AddStoreToCouponGroupMutation, AddStoreToCouponGroupMutationVariables>;
export const RemoveStoreFromCouponGroupDocument = gql`
    mutation RemoveStoreFromCouponGroup($input: RemoveStoreFromCouponGroupInput!) {
  removeStoreFromCouponGroup(input: $input) {
    id
    stores {
      id
      name
      internalCode
    }
  }
}
    `;
export type RemoveStoreFromCouponGroupMutationFn = Apollo.MutationFunction<RemoveStoreFromCouponGroupMutation, RemoveStoreFromCouponGroupMutationVariables>;

/**
 * __useRemoveStoreFromCouponGroupMutation__
 *
 * To run a mutation, you first call `useRemoveStoreFromCouponGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveStoreFromCouponGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeStoreFromCouponGroupMutation, { data, loading, error }] = useRemoveStoreFromCouponGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveStoreFromCouponGroupMutation(baseOptions?: Apollo.MutationHookOptions<RemoveStoreFromCouponGroupMutation, RemoveStoreFromCouponGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveStoreFromCouponGroupMutation, RemoveStoreFromCouponGroupMutationVariables>(RemoveStoreFromCouponGroupDocument, options);
      }
export type RemoveStoreFromCouponGroupMutationHookResult = ReturnType<typeof useRemoveStoreFromCouponGroupMutation>;
export type RemoveStoreFromCouponGroupMutationResult = Apollo.MutationResult<RemoveStoreFromCouponGroupMutation>;
export type RemoveStoreFromCouponGroupMutationOptions = Apollo.BaseMutationOptions<RemoveStoreFromCouponGroupMutation, RemoveStoreFromCouponGroupMutationVariables>;
export const AddCouponGroupToCouponDocument = gql`
    mutation AddCouponGroupToCoupon($input: AddCouponGroupToCouponInput!) {
  addCouponGroupToCoupon(input: $input) {
    coupon {
      id
      name
      internalCode
      discountPercentage
      maxDiscountAmount
      startDate
      endDate
      enabled
      totalGroups
    }
  }
}
    `;
export type AddCouponGroupToCouponMutationFn = Apollo.MutationFunction<AddCouponGroupToCouponMutation, AddCouponGroupToCouponMutationVariables>;

/**
 * __useAddCouponGroupToCouponMutation__
 *
 * To run a mutation, you first call `useAddCouponGroupToCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCouponGroupToCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCouponGroupToCouponMutation, { data, loading, error }] = useAddCouponGroupToCouponMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCouponGroupToCouponMutation(baseOptions?: Apollo.MutationHookOptions<AddCouponGroupToCouponMutation, AddCouponGroupToCouponMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCouponGroupToCouponMutation, AddCouponGroupToCouponMutationVariables>(AddCouponGroupToCouponDocument, options);
      }
export type AddCouponGroupToCouponMutationHookResult = ReturnType<typeof useAddCouponGroupToCouponMutation>;
export type AddCouponGroupToCouponMutationResult = Apollo.MutationResult<AddCouponGroupToCouponMutation>;
export type AddCouponGroupToCouponMutationOptions = Apollo.BaseMutationOptions<AddCouponGroupToCouponMutation, AddCouponGroupToCouponMutationVariables>;
export const RemoveCouponGroupFromCouponDocument = gql`
    mutation RemoveCouponGroupFromCoupon($input: RemoveCouponGroupFromCouponInput!) {
  removeCouponGroupFromCoupon(input: $input) {
    coupon {
      id
      name
      internalCode
      discountPercentage
      maxDiscountAmount
      startDate
      endDate
      enabled
      totalGroups
    }
  }
}
    `;
export type RemoveCouponGroupFromCouponMutationFn = Apollo.MutationFunction<RemoveCouponGroupFromCouponMutation, RemoveCouponGroupFromCouponMutationVariables>;

/**
 * __useRemoveCouponGroupFromCouponMutation__
 *
 * To run a mutation, you first call `useRemoveCouponGroupFromCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCouponGroupFromCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCouponGroupFromCouponMutation, { data, loading, error }] = useRemoveCouponGroupFromCouponMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveCouponGroupFromCouponMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCouponGroupFromCouponMutation, RemoveCouponGroupFromCouponMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveCouponGroupFromCouponMutation, RemoveCouponGroupFromCouponMutationVariables>(RemoveCouponGroupFromCouponDocument, options);
      }
export type RemoveCouponGroupFromCouponMutationHookResult = ReturnType<typeof useRemoveCouponGroupFromCouponMutation>;
export type RemoveCouponGroupFromCouponMutationResult = Apollo.MutationResult<RemoveCouponGroupFromCouponMutation>;
export type RemoveCouponGroupFromCouponMutationOptions = Apollo.BaseMutationOptions<RemoveCouponGroupFromCouponMutation, RemoveCouponGroupFromCouponMutationVariables>;