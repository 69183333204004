import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FindMySiteConfigurationQueryVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.FindSiteConfigurationInput>;
}>;


export type FindMySiteConfigurationQuery = { __typename?: 'Query', findMySiteConfiguration?: { __typename?: 'SiteConfigurationResponse', siteConfiguration?: { __typename?: 'SiteConfiguration', supplierId: number, name: string, subdomain: string, favicon: string, logo: string, icon: string, onboardingTitle: string, onboardingDescription: string, primaryPrincipalColor: string, backgroundImage: string, backgroundImageDesktop?: string | null, locale?: string | null, recommendations: boolean, conversationalChannel?: { __typename?: 'ConversationalChannel', id: number, phoneNumber: string, botId: string, channelId: string } | null } | null } | null };


export const FindMySiteConfigurationDocument = gql`
    query FindMySiteConfiguration($input: FindSiteConfigurationInput) {
  findMySiteConfiguration(input: $input) {
    siteConfiguration {
      supplierId
      name
      subdomain
      favicon
      logo
      icon
      onboardingTitle
      onboardingDescription
      primaryPrincipalColor
      backgroundImage
      backgroundImageDesktop
      locale
      conversationalChannel {
        id
        phoneNumber
        botId
        channelId
      }
      recommendations
    }
  }
}
    `;

/**
 * __useFindMySiteConfigurationQuery__
 *
 * To run a query within a React component, call `useFindMySiteConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindMySiteConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindMySiteConfigurationQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindMySiteConfigurationQuery(baseOptions?: Apollo.QueryHookOptions<FindMySiteConfigurationQuery, FindMySiteConfigurationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindMySiteConfigurationQuery, FindMySiteConfigurationQueryVariables>(FindMySiteConfigurationDocument, options);
      }
export function useFindMySiteConfigurationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindMySiteConfigurationQuery, FindMySiteConfigurationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindMySiteConfigurationQuery, FindMySiteConfigurationQueryVariables>(FindMySiteConfigurationDocument, options);
        }
export type FindMySiteConfigurationQueryHookResult = ReturnType<typeof useFindMySiteConfigurationQuery>;
export type FindMySiteConfigurationLazyQueryHookResult = ReturnType<typeof useFindMySiteConfigurationLazyQuery>;
export type FindMySiteConfigurationQueryResult = Apollo.QueryResult<FindMySiteConfigurationQuery, FindMySiteConfigurationQueryVariables>;