import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FindPromotionsForStoreQueryVariables = Types.Exact<{
  input: Types.FindPromotionsForStoreInput;
}>;


export type FindPromotionsForStoreQuery = { __typename?: 'Query', findPromotionsForStore: Array<{ __typename?: 'PromotionV2', id: number, type: Types.PromotionType, updatedAt: number, discountTiers?: Array<{ __typename?: 'DiscountTier', id: number, min: number, max?: number | null, discountPercentage?: number | null, discountAmount?: number | null, currency?: string | null, scale?: number | null, tierType: Types.DiscountTierType }> | null, bonusTier?: { __typename?: 'BonusTierDetail', id: number, quantityRequired: number, rewardedProductDetails: { __typename?: 'RewardedProductDetails', productId: number, productName: string, productDescription: string, productImage?: string | null, productInternalCode?: string | null, displayProductUnits: number, displayProductId: number, rewardedQuantity: number } } | null, products: Array<{ __typename?: 'ProductInPromotionV2', id: number, boxRatio: number, displays: Array<{ __typename?: 'DisplayProductInPromotionV2', id: number, productId: number, units: number, unitPrice: { __typename?: 'Money', amount: number, currency: string, scale: number }, price: { __typename?: 'Money', amount: number, currency: string, scale: number } }> }> }> };


export const FindPromotionsForStoreDocument = gql`
    query FindPromotionsForStore($input: FindPromotionsForStoreInput!) {
  findPromotionsForStore(findPromotionsForStoreInput: $input) {
    id
    type
    updatedAt
    discountTiers {
      id
      min
      max
      discountPercentage
      discountAmount
      currency
      scale
      tierType
    }
    bonusTier {
      id
      quantityRequired
      rewardedProductDetails {
        productId
        productName
        productDescription
        productImage
        productInternalCode
        displayProductUnits
        displayProductId
        rewardedQuantity
      }
    }
    products {
      id
      boxRatio
      displays {
        id
        productId
        units
        unitPrice {
          amount
          currency
          scale
        }
        price {
          amount
          currency
          scale
        }
      }
    }
  }
}
    `;

/**
 * __useFindPromotionsForStoreQuery__
 *
 * To run a query within a React component, call `useFindPromotionsForStoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindPromotionsForStoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindPromotionsForStoreQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindPromotionsForStoreQuery(baseOptions: Apollo.QueryHookOptions<FindPromotionsForStoreQuery, FindPromotionsForStoreQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindPromotionsForStoreQuery, FindPromotionsForStoreQueryVariables>(FindPromotionsForStoreDocument, options);
      }
export function useFindPromotionsForStoreLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindPromotionsForStoreQuery, FindPromotionsForStoreQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindPromotionsForStoreQuery, FindPromotionsForStoreQueryVariables>(FindPromotionsForStoreDocument, options);
        }
export type FindPromotionsForStoreQueryHookResult = ReturnType<typeof useFindPromotionsForStoreQuery>;
export type FindPromotionsForStoreLazyQueryHookResult = ReturnType<typeof useFindPromotionsForStoreLazyQuery>;
export type FindPromotionsForStoreQueryResult = Apollo.QueryResult<FindPromotionsForStoreQuery, FindPromotionsForStoreQueryVariables>;